import logo from "./logo.svg";
import "./App.css";
import PodsDashboard from "./PodsDashboard";

function App() {
  return (
    <div className="App">
      <PodsDashboard />
    </div>
  );
}

export default App;
