import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashAlt,
  faExternalLinkAlt,
  faTerminal,
} from "@fortawesome/free-solid-svg-icons";
import {
  faSignOutAlt,
  faPlusCircle,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons"; // Add FontAwesome icons

function PodsDashboard() {
  const [config, setConfig] = useState({});

  useEffect(() => {
    fetch("/config/config.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to load configuration");
        }
        return response.json();
      })
      .then((config) => {
        //console.log(config);
        setConfig(config); // Store the whole config
      })
      .catch((error) => {
        console.error("Error fetching config:", error);
      });
  }, []);

  const appName = config.appName;
  const apiURL = config.apiURL;
  const DNS_NAME = config.DNS_NAME;

  // State for UI Password
  const [uiPassword, setUiPassword] = useState("");
  const [uiAuthenticated, setUiAuthenticated] = useState(
    () => sessionStorage.getItem("uiAuthenticated") === "true"
  );

  // State for API Token and Pod Data
  const [token, setToken] = useState(
    () => sessionStorage.getItem("token") || null
  );
  const [podsData, setPodsData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // Static passwords (replace with secure values)
  const staticUiPassword = config.uiKey;
  const staticApiPassword = config.apiKey;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [templateID, setTemplateID] = useState("");

  const [clientNameError, setClientNameError] = useState("");
  const [clientEmailError, setClientEmailError] = useState("");
  const [templateIDError, setTemplateIDError] = useState("");

  const [templateOptions, setTemplateOptions] = useState([]);

  const [isHovered, setIsHovered] = useState(false);

  const [notification, setNotification] = useState({ message: "", type: "" });
  const [isLoading, setIsLoading] = useState(false);

  const [isCreateHovered, setIsCreateHovered] = useState(false);
  const [isCancelHovered, setIsCancelHovered] = useState(false);

  const [isLogoutHovered, setIsLogoutHovered] = useState(false);
  const [isCreatePodHovered, setIsCreatePodHovered] = useState(false);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [podToDelete, setPodToDelete] = useState({
    clientName: "",
    clientEmail: "",
  });

  // New states for executing commands
  const [showCommandInput, setShowCommandInput] = useState(false);
  const [command, setCommand] = useState("");
  const [selectedPod, setSelectedPod] = useState(null);
  const [isExecuting, setIsExecuting] = useState(false); // To track loading state during execution
  const [executeTarget, setExecuteTarget] = useState("all"); // 'all' or 'single'

  const [isExecHovered, setIsExecHovered] = useState(false);
  const [isExecAllHovered, setIsExecAllHovered] = useState(false);

  const handleUiLogin = async () => {
    setError(""); // Clear the error before attempting login
    if (uiPassword === staticUiPassword) {
      try {
        // Login to the API automatically on UI login
        const response = await axios.post(`https://${apiURL}/login`, {
          password: staticApiPassword, // Using static API password
        });
        const receivedToken = response.data.token;
        setToken(receivedToken);
        sessionStorage.setItem("token", receivedToken);
        sessionStorage.setItem("uiAuthenticated", "true");
        setError("");
        setUiAuthenticated(true);
      } catch (err) {
        console.error("API Login error:", err);
        setError("API Login failed: Unable to authenticate with API.");
      }
    } else {
      if (!uiAuthenticated) {
        setError("Login failed: Invalid password.");
      }
    }
  };

  const handleLogout = () => {
    setToken(null);
    setPodsData(null);
    setUiAuthenticated(false);
    sessionStorage.removeItem("uiAuthenticated");
    sessionStorage.removeItem("token");
  };

  // Fetch pods data from the API
  const fetchPodsData = () => {
    handleUiLogin();
    if (token) {
      setLoading(true);
      axios
        .post(
          `https://${apiURL}/list-pods`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setPodsData(response.data);
          setError("");
        })
        .catch((err) => {
          setError("Failed to fetch pod data");
          console.error("Error fetching pod data:", err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  // Function to handle creating a new pod
  const handleCreatePodSubmit = async (e) => {
    e.preventDefault();

    let hasError = false;

    // Validation logic...
    if (!clientName || /\s/.test(clientName)) {
      setClientNameError("Client name is required and should have no spaces.");
      hasError = true;
    } else {
      setClientNameError("");
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!clientEmail || !emailRegex.test(clientEmail)) {
      setClientEmailError("A valid email is required.");
      hasError = true;
    } else {
      setClientEmailError("");
    }

    if (!templateID || isNaN(templateID)) {
      setTemplateIDError("Template ID is required and should be a number.");
      hasError = true;
    } else {
      setTemplateIDError("");
    }

    if (hasError) return;

    setIsLoading(true); // Start loading
    try {
      await axios.post(
        `https://${apiURL}/provision-pod`,
        { clientName, clientEmail, templateID },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchPodsData();
      setNotification({
        message: "Pod created successfully!",
        type: "success",
      });
      setIsModalOpen(false);
      setClientName("");
      setClientEmail("");
      setTemplateID("");
    } catch (err) {
      console.error("Error creating pod:", err);
      const errorMessage = err.response?.data?.error || "Failed to create pod.";
      setNotification({ message: errorMessage, type: "error" });
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handleExecAllPods = async () => {
    if (!command) return;

    setIsExecuting(true);
    try {
      const response = await axios.post(
        `https://${apiURL}/exec-odoo-all`,
        { command },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setNotification({
        message: (
          <span>
            Success! LogFile:{" "}
            <a
              href={response?.data?.logFile}
              target="_blank"
              rel="noopener noreferrer"
            >
              {response?.data?.logFile}
            </a>
          </span>
        ),
        type: "success",
      });
    } catch (err) {
      setNotification({
        message: "Failed to execute command on all pods.",
        type: "error",
      });
    } finally {
      setIsExecuting(false);
      setShowCommandInput(false);
      setCommand(""); // Clear the input field before showing it again
    }
  };

  const handleExecSinglePod = async () => {
    if (!command || !selectedPod) return;

    setIsExecuting(true);
    try {
      const response = await axios.post(
        `https://${apiURL}/exec-odoo`,
        { command, podName: selectedPod },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setNotification({
        message: (
          <span>
            Success! LogFile:{" "}
            <a
              href={response?.data?.logFile}
              target="_blank"
              rel="noopener noreferrer"
            >
              {response?.data?.logFile}
            </a>
          </span>
        ),
        type: "success",
      });
    } catch (err) {
      setNotification({
        message: `Failed to execute command on pod ${selectedPod}.`,
        type: "error",
      });
    } finally {
      setIsExecuting(false);
      setShowCommandInput(false);
      setCommand(""); // Clear the input field before showing it again
    }
  };

  // Fetch available template IDs when the component mounts
  const fetchTemplateIDs = async () => {
    try {
      const response = await axios.post(
        `https://${apiURL}/get-templates`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setTemplateOptions(response.data); // Populate the dropdown options
    } catch (err) {
      console.error("Error fetching template IDs:", err);
    }
  };

  // Fetch pod data immediately if token exists, and every 30 seconds thereafter
  useEffect(() => {
    if (token) {
      fetchPodsData();
    }
    const interval = setInterval(() => {
      if (token) {
        fetchPodsData();
      }
    }, 60000); // Refresh every 30 seconds

    return () => clearInterval(interval);
  }, [token]);

  // Fetch pod data when UI is authenticated and token is restored from sessionStorage
  useEffect(() => {
    if (uiAuthenticated && !podsData) {
      const storedToken = sessionStorage.getItem("token");
      if (storedToken) {
        setToken(storedToken);
        fetchPodsData();
      }
    }
  }, [uiAuthenticated]);

  document.title = `${appName} Dashboard`;

  // Handle delete pod action
  const handleDeletePodClick = (clientName, clientEmail) => {
    setPodToDelete({ clientName, clientEmail });
    setIsConfirmModalOpen(true); // Open the modal
  };

  const confirmDeletePod = async () => {
    setIsLoading(true);
    try {
      await axios.post(
        `https://${apiURL}/delete-pod`,
        {
          clientName: podToDelete.clientName,
          clientEmail: podToDelete.clientEmail,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      fetchPodsData(); // Refresh pod data after deletion
      setNotification({
        message: "Pod deleted successfully!",
        type: "success",
      });
    } catch (err) {
      const errorMessage = err.response?.data?.error || "Failed to delete pod.";
      setNotification({ message: errorMessage, type: "error" });
      console.error("Error deleting pod:", err);
    } finally {
      setIsLoading(false);
      setIsConfirmModalOpen(false); // Close the modal
    }
  };

  // Render card UI based on pod data categories
  return (
    <div style={styles.container}>
      {!uiAuthenticated ? (
        <div style={styles.loginBox}>
          <h2 style={styles.loginTitle}>{appName} Dashboard</h2>
          <p style={styles.loginSubtitle}>
            Please enter your password to access
          </p>
          <input
            type="password"
            value={uiPassword}
            onChange={(e) => setUiPassword(e.target.value)}
            placeholder="Enter UI Password"
            style={styles.input}
          />
          <button
            onClick={handleUiLogin}
            style={
              isHovered
                ? { ...styles.button, ...styles.buttonHover }
                : styles.button
            }
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            Login
          </button>
          {error && <p style={styles.loginError}>{error}</p>}
        </div>
      ) : (
        <div>
          <button
            onClick={() => {
              setIsModalOpen(true);
              fetchTemplateIDs();
            }}
            style={{
              ...(isCreatePodHovered
                ? styles.createPodButtonHover
                : styles.createPodButton),
            }}
            onMouseEnter={() => setIsCreatePodHovered(true)}
            onMouseLeave={() => setIsCreatePodHovered(false)}
          >
            <FontAwesomeIcon icon={faPlusCircle} /> Create New Pod
          </button>
          <button
            onClick={() => {
              setShowCommandInput(true);
              setExecuteTarget("all");
            }}
            style={styles.execAllPodsButton}
          >
            <FontAwesomeIcon icon={faTerminal} /> Execute on All Pods
          </button>
          {showCommandInput && (
            <div style={styles.commandInputModalOverlay}>
              <div style={styles.commandInputModalContent}>
                <input
                  type="text"
                  value={command}
                  onChange={(e) => setCommand(e.target.value)}
                  placeholder="Enter command to execute"
                  style={styles.commandInput}
                />
                <button
                  onClick={() => {
                    if (executeTarget === "all") {
                      handleExecAllPods();
                    } else if (executeTarget === "single") {
                      handleExecSinglePod();
                    }
                  }}
                  style={styles.execButton}
                >
                  {isExecuting ? "Executing..." : "Execute"}
                </button>
                <button
                  onClick={() => {
                    setShowCommandInput(false);
                    setCommand(""); // Clear the input field
                  }}
                  style={
                    isCancelHovered
                      ? styles.cancelButtonHover
                      : styles.cancelButton
                  }
                  onMouseEnter={() => setIsCancelHovered(true)}
                  onMouseLeave={() => setIsCancelHovered(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}

          <button
            onClick={handleLogout}
            style={{
              ...(isLogoutHovered
                ? styles.logoutButtonHover
                : styles.logoutButton),
            }}
            onMouseEnter={() => setIsLogoutHovered(true)}
            onMouseLeave={() => setIsLogoutHovered(false)}
          >
            <FontAwesomeIcon icon={faSignOutAlt} /> Logout
          </button>
          {isModalOpen && (
            <div style={styles.modalOverlay}>
              <div style={styles.modalContent}>
                {isLoading ? (
                  <div style={styles.loadingOverlay}>
                    <p>Loading...</p>
                  </div>
                ) : (
                  <>
                    <h2>Create New Pod</h2>
                    <form onSubmit={handleCreatePodSubmit}>
                      <label style={styles.label}>
                        Client Name <span style={styles.required}>*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Client Name"
                        value={clientName}
                        onChange={(e) => setClientName(e.target.value)}
                        style={styles.inputField}
                      />
                      {clientNameError && (
                        <p style={styles.errorText}>{clientNameError}</p>
                      )}

                      <label style={styles.label}>
                        Client Email <span style={styles.required}>*</span>
                      </label>
                      <input
                        type="email"
                        placeholder="Client Email"
                        value={clientEmail}
                        onChange={(e) => setClientEmail(e.target.value)}
                        style={styles.inputField}
                      />
                      {clientEmailError && (
                        <p style={styles.errorText}>{clientEmailError}</p>
                      )}

                      <label style={styles.label}>
                        Template ID <span style={styles.required}>*</span>
                      </label>
                      <select
                        value={templateID}
                        onChange={(e) => setTemplateID(e.target.value)}
                        style={styles.inputField}
                      >
                        <option value="" disabled>
                          Select a Template ID
                        </option>
                        {templateOptions.map((id) => (
                          <option key={id} value={id}>
                            {id}
                          </option>
                        ))}
                      </select>
                      {templateIDError && (
                        <p style={styles.errorText}>{templateIDError}</p>
                      )}

                      <button
                        type="submit"
                        style={{
                          ...(isCreateHovered
                            ? styles.submitButtonHover
                            : styles.submitButton),
                        }}
                        onMouseEnter={() => setIsCreateHovered(true)}
                        onMouseLeave={() => setIsCreateHovered(false)}
                      >
                        Create Pod
                      </button>
                    </form>
                    <button
                      onClick={() => setIsModalOpen(false)}
                      style={{
                        ...(isCancelHovered
                          ? styles.closeButtonHover
                          : styles.closeButton),
                      }}
                      onMouseEnter={() => setIsCancelHovered(true)}
                      onMouseLeave={() => setIsCancelHovered(false)}
                    >
                      Cancel
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
          {notification.message && (
            <div
              style={{
                ...styles.notification,
                ...styles[
                  notification.type === "success"
                    ? "success"
                    : "errorNotification"
                ],
              }}
            >
              {notification.message}
              <button
                onClick={() => setNotification({ message: "", type: "" })}
                style={styles.notificationCloseButton}
              >
                &times;
              </button>
            </div>
          )}

          {isConfirmModalOpen && (
            <div style={styles.ConfirmModalOverlay}>
              <div style={styles.ConfirmModalContent}>
                <h3 style={styles.ConfirmModalTitle}>
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    style={{ color: "#ff4d4d", marginRight: "10px" }}
                  />
                  Confirm Deletion
                </h3>
                <p>
                  Are you sure you want to delete pod:{" "}
                  <strong>{podToDelete.clientName}</strong>?
                </p>
                <div style={styles.ConfirmButtonContainer}>
                  <button
                    onClick={confirmDeletePod}
                    style={{
                      ...styles.ConfirmButtonBase,
                      ...styles.ConfirmRedButton,
                    }}
                  >
                    Yes, Delete
                  </button>
                  <button
                    onClick={() => setIsConfirmModalOpen(false)}
                    style={{
                      ...styles.ConfirmButtonBase,
                      ...styles.ConfirmGrayButton,
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}

          <h1>{appName} Dashboard</h1>
          {loading && <p style={styles.loading}>Loading...</p>}
          {error && <p style={styles.error}>{error}</p>}
          {podsData && (
            <>
              <h2>Working Pods:</h2>
              <div style={styles.cardsContainer}>
                {podsData.workingPods.map((pod) => (
                  <div
                    key={pod.clientName}
                    style={{ ...styles.card, ...styles.workingCard }}
                  >
                    <div style={styles.cardNameTop}>{pod.clientName}</div>
                    <small style={styles.creationDate}>
                      Created on: {pod.creationDate}
                    </small>
                    <div style={styles.buttonContainer}>
                      <button
                        onClick={() =>
                          handleDeletePodClick(pod.clientName, pod.clientEmail)
                        }
                        style={{ ...styles.iconButton, ...styles.deleteButton }}
                        title="Delete Pod"
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </button>
                      <button
                        style={{ ...styles.iconButton, ...styles.visitButton }}
                        title="Visit Pod"
                        onClick={() =>
                          window.open(
                            `https://${pod.clientName}.${DNS_NAME}`,
                            "_blank"
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                      </button>
                      <button
                        style={{
                          ...styles.iconButton,
                          ...styles.execPodButton,
                        }}
                        title="Execute Command"
                        onClick={() => {
                          setSelectedPod(pod.clientName); // Store selected pod name
                          setShowCommandInput(true);
                          setExecuteTarget("single");
                        }}
                      >
                        <FontAwesomeIcon icon={faTerminal} />
                      </button>
                      <button
                        style={{
                          ...styles.iconButton,
                          ...styles.logsButton, // Apply unique styles for the logs button
                        }}
                        title="Odoo Logs"
                        onClick={() =>
                          window.open(
                            `https://${apiURL}/odoologs/${pod.clientName}`,
                            "_blank"
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faFileAlt} />
                      </button>
                    </div>
                  </div>
                ))}
              </div>

              <h2>Orphaned Folders:</h2>
              <div style={styles.cardsContainer}>
                {podsData.orphanedFolders.map((folder) => (
                  <div
                    key={folder.clientName}
                    style={{ ...styles.card, ...styles.orphanedCard }}
                  >
                    <div style={styles.cardNameCenter}>{folder.clientName}</div>
                    <small style={styles.creationDate}>
                      Created on: {folder.creationDate}
                    </small>
                  </div>
                ))}
              </div>

              <h2>Deleted Pods:</h2>
              <div style={styles.cardsContainer}>
                {podsData.deletedPods.map((folder) => (
                  <div
                    key={folder.clientName}
                    style={{ ...styles.card, ...styles.deletedCard }}
                  >
                    <div style={styles.cardNameCenter}>{folder.clientName}</div>
                    <small style={styles.creationDate}>
                      Created on: {folder.creationDate}
                    </small>
                    <small style={styles.deletionDate}>
                      Deleted on: {folder.deletionDate}
                    </small>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

const styles = {
  container: {
    fontFamily: "'Arial', sans-serif",
    padding: "20px",
    backgroundColor: "#f5f5f5",
    minHeight: "100vh",
    position: "relative",
  },
  loginBox: {
    maxWidth: "400px",
    margin: "80px auto",
    padding: "40px",
    backgroundColor: "#ffffff",
    borderRadius: "12px",
    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
  },
  loginTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    color: "#333",
    marginBottom: "10px",
  },
  loginSubtitle: {
    fontSize: "14px",
    color: "#666",
    marginBottom: "20px",
  },
  input: {
    width: "100%",
    padding: "12px",
    marginBottom: "20px",
    borderRadius: "8px",
    border: "1px solid #ddd",
    fontSize: "16px",
    color: "#333",
  },
  button: {
    width: "100%",
    padding: "12px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    fontSize: "16px",
    fontWeight: "bold",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  buttonHover: {
    backgroundColor: "#0056b3",
  },
  loginError: {
    color: "#d9534f",
    fontSize: "14px",
    marginTop: "15px",
  },
  createPodButton: {
    position: "absolute",
    top: "20px",
    left: "20px",
    padding: "10px 20px",
    backgroundColor: "#28a745", // Green color
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontSize: "14px",
  },
  createPodButtonHover: {
    position: "absolute",
    top: "20px",
    left: "20px",
    padding: "10px 20px",
    backgroundColor: "#218838", // Green color
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontSize: "14px",
  },
  logoutButton: {
    position: "absolute",
    top: "20px",
    right: "20px",
    padding: "10px 20px",
    backgroundColor: "#ff4d4d",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontSize: "14px",
  },
  logoutButtonHover: {
    position: "absolute",
    top: "20px",
    right: "20px",
    padding: "10px 20px",
    backgroundColor: "#c82333",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    fontSize: "14px",
  },
  error: { color: "red" },
  loading: { color: "#007bff" },
  cardsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    marginTop: "20px",
    padding: "15px", // Adds spacing inside the frame
    border: "2px solid #ccc", // Adds a light grey border around each list
    borderRadius: "10px", // Rounds the corners of the frame
    backgroundColor: "#f9f9f9", // Optional: adds a background color for contrast
  },
  card: {
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 0 10px rgba(0,0,0,0.1)",
    width: "200px",
    textAlign: "center",
    position: "relative",
  },
  workingCard: { backgroundColor: "#d4edda" },
  orphanedCard: { backgroundColor: "#ffeeba" },
  deletedCard: { backgroundColor: "#f8d7da" },
  cardNameTop: { fontSize: "18px", fontWeight: "bold", marginBottom: "8px" },
  cardNameCenter: { fontSize: "18px", fontWeight: "bold", marginBottom: "8px" },
  creationDate: {
    fontSize: "12px",
    color: "#555",
    display: "block",
    marginTop: "5px",
  },
  deletionDate: {
    fontSize: "12px",
    color: "#d9534f",
    display: "block",
    marginTop: "5px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    marginTop: "10px",
  },
  iconButton: {
    padding: "5px",
    border: "none",
    backgroundColor: "transparent",
    cursor: "pointer",
    fontSize: "18px",
  },
  deleteButton: {
    color: "#ff4d4d", // Red for delete button
  },
  visitButton: {
    color: "#007bff", // Blue for visit button
  },
  modalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  modalContent: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    width: "300px",
    maxWidth: "90%", // Prevent overflow on smaller screens
    boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
    textAlign: "center",
  },
  inputField: {
    width: "90%",
    padding: "10px",
    margin: "5px 0 15px 0",
    borderRadius: "5px",
    border: "1px solid #ccc",
    fontSize: "14px",
  },
  submitButton: {
    backgroundColor: "#28a745",
    color: "#fff",
    padding: "10px 20px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
    marginTop: "10px",
  },
  submitButtonHover: {
    backgroundColor: "#218838",
    color: "#fff",
    padding: "10px 20px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
    marginTop: "10px",
  },
  closeButton: {
    marginTop: "10px",
    backgroundColor: "#ff4d4d",
    color: "#fff",
    padding: "8px 16px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
  },
  closeButtonHover: {
    marginTop: "10px",
    color: "#fff",
    padding: "8px 16px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
    backgroundColor: "#c82333", // Darker red on hover
  },
  errorText: {
    color: "#d9534f",
    fontSize: "12px",
    marginTop: "-10px",
    marginBottom: "10px",
    textAlign: "left",
  },
  label: {
    fontSize: "14px",
    fontWeight: "bold",
    display: "block",
    marginBottom: "5px",
  },
  required: {
    color: "#d9534f",
  },

  notification: {
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    padding: "10px",
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "16px",
    zIndex: 2000,
  },
  success: {
    backgroundColor: "#28a745",
  },
  errorNotification: {
    backgroundColor: "#dc3545",
  },
  notificationCloseButton: {
    marginLeft: "10px",
    backgroundColor: "transparent",
    color: "#fff",
    border: "none",
    fontSize: "20px",
    cursor: "pointer",
  },
  loadingText: {
    fontSize: "16px",
    color: "#007bff",
    textAlign: "center",
    padding: "20px 0",
  },
  buttonBase: {
    padding: "10px 20px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
    transition: "background-color 0.3s ease",
  },
  loadingOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.8)", // Semi-transparent background
    fontSize: "18px",
    color: "#007bff",
    zIndex: 1001, // Higher than modal content
  },

  ConfirmModalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  ConfirmModalContent: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    width: "300px",
    maxWidth: "90%",
    boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
    textAlign: "center",
  },
  ConfirmModalTitle: {
    fontSize: "18px",
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ConfirmButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  ConfirmButtonBase: {
    padding: "10px 20px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
  },
  ConfirmRedButton: {
    backgroundColor: "#ff4d4d",
    color: "#fff",
  },
  ConfirmGrayButton: {
    backgroundColor: "#ccc",
    color: "#333",
  },
  execAllPodsButton: {
    position: "absolute",
    top: "20px",
    left: "200px",
    padding: "10px 20px",
    backgroundColor: "#6c757d",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "14px",
  },

  commandInputContainer: {
    marginTop: "15px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  commandInputModalOverlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black to create an overlay effect
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000, // Ensures it floats above all other content
  },

  commandInputModalContent: {
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "8px",
    width: "300px",
    maxWidth: "90%", // Prevent overflow on smaller screens
    boxShadow: "0 5px 15px rgba(0, 0, 0, 0.3)",
    textAlign: "center",
  },

  commandInput: {
    width: "90%",
    padding: "10px",
    margin: "10px 0",
    borderRadius: "5px",
    border: "1px solid #ccc",
    fontSize: "14px",
  },

  execButton: {
    padding: "10px 20px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "14px",
  },

  execPodButton: {
    color: "#28a745", // Green color for execute button
  },
  cancelButton: {
    margin: "20px", // Increased margin to add space between Execute and Cancel buttons
    backgroundColor: "#ff4d4d", // Red color for cancel button
    color: "#fff",
    padding: "10px 20px", // Same padding as the Execute button
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
  },

  cancelButtonHover: {
    margin: "20px", // Consistent margin-top with the normal button
    backgroundColor: "#c82333", // Darker red on hover
    color: "#fff",
    padding: "10px 20px", // Same padding as the Execute button
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    fontSize: "14px",
  },
  logsButton: {
    color: "#ffc107", // Yellow color for logs button
    fontSize: "18px", // Make it slightly larger for emphasis
  },
};

export default PodsDashboard;
